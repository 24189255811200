<template>
  <v-app-bar class="appBar" dense app elevation="0">
    <v-app-bar-nav-icon @click="toggleDrawer()">
      <v-icon>mdi-menu</v-icon>
    </v-app-bar-nav-icon>
    <v-spacer></v-spacer>
    <h5
      v-if="this.$store.state.userIsCoreMember"
      class="blue--text text--darken-2 text-capitalize mr-2"
      style="font-size: 16px; font-weight: 400"
      @click="editDoc()"
    >
      <b v-if="editDocStatus">
        {{ editDocStatus ? "Done" : "Edit" }}
      </b>
      <div v-if="!editDocStatus">
        {{ editDocStatus ? "Done" : "Edit" }}
      </div>
    </h5>
  </v-app-bar>
</template>
<script>
//import { bus } from "../main";

export default {
  name: "DocsAppBar",
  data: () => ({
    test: "Test",
  }),
  computed: {
    calendarTitle() {
      var thisYearOptions = { year: "numeric", month: "long" };
      var date =
        this.$store.state.calendar.focus !== ""
          ? new Date(this.$store.state.calendar.focus)
          : new Date();
      date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
      return date.toLocaleDateString("en-US", thisYearOptions);
    },
    editDocStatus() {
      return this.$store.state.editDoc;
    },
  },
  methods: {
    toggleDrawer() {
      this.$store.commit("toggleDrawer", ["docs", true]);
    },
    editDoc() {
      this.$store.commit("editDoc");
    },
  },
};
</script>
